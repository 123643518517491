<template>
  <!-- <link rel="prefetch" :href="svgsprite"> -->
  <router-view />
</template>

<style lang="css" src="@/assets/fonts/sfns.css"></style>
<style lang="scss" src="@/assets/colors.scss"></style>
<style lang="scss" src="@/assets/base.scss"></style>
<style lang="scss" src="@/assets/button.scss"></style>

<style lang="scss">
#app {
  font-family: sfns, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: var(--text-color);
  
  #button-sim {
    position: fixed;
    left: 0; right: 0;
    top: calc(100vh - 75px);
    height: 75px;
    background: #f00;
    display: none;
  }
  
  header.main {
    background: var(--body-bgcolor);
    border-bottom: 1px solid rgba(128,128,128,0.5);
    height: 44px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 100;

    h1 {
      text-align: center;
      margin: 0;
      padding-top: 12px;
      line-height: 32px;
      color: var(--neg-bgcolor);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;

      a {
        color: var(--neg-bgcolor);
        text-decoration-color: white;
        text-decoration: none;
      }

      @keyframes pulse {
        0% { color: var(--text-color); }
        50% { color: var(--good-text-color); }
        100% { color: var(--text-color); }
      }
      
      @keyframes pulse-par {
        0% { color: var(--text-color); }
        50% { color: rgb(193, 0, 0); text-shadow: 0 0 8px rgba(255,255,255,0.4); }
        100% { color: var(--text-color); }
      }
      
      .co { font-weight: 100; }
      .br { color: var(--text-dim3-color); text-decoration: line-through; transition: all 1s ease;}
      .br.live { text-decoration: none; animation: pulse 5s infinite;}
      .br.live-par { text-decoration: none;animation: pulse-par 5s infinite;}
    
      .re-name { margin-left: 8px; }
      .sel-re-enter-active,
      .sel-re-leave-active { transition: all 0.44s; }
      .sel-re-enter-from { width: 0; opacity: 0; transform: translateY(-32px); }
      .sel-re-leave-to { opacity: 0; }
    
      &:before {
        content: '.';
        display: inline-block;
        margin-right: 0;
        mask-image: url('@/assets/img/aravaipa-logo.svg');
        background-color: var(--text-color);
        width:  48px;
        height: 48px;
        text-indent: -9999px;
        transform: scale(0.5) translateY(-18px); 
        transition: scale 0.4s ease;
      }
    }
  }
}
</style>

<script>
import eventbus from '@/eventbus'
import svgsprite from '@/assets/icon/svgsprites.svg'
import { computed } from 'vue'

export default {
  name: 'LiveMap',
  components: {

  },
  
  provide() {
    let self = this;
    return {
      layout: computed(() => ({ 
        isMobile: self.isMobileInterface
      }))
    }
  },
  
  data() {
    return {
      uptime: 0,
      // Needs onResize updating
      isMobileInterface: document.documentElement.clientWidth < 450,
    }
  },
  
  computed: {
    wantsMiles: {
      get()  { return this.settings.wantsMiles },
      set(v) { this.settings.unitPreference = v ? 'mi' : 'km'; console.log("settings now", this.settings) },
    }
  },
  
  mounted() {
    window.addEventListener('resize', this.documentResized, {
      passive: true,
      useCapture: false
    });
    
    setInterval(() => {
      eventbus.emit('tick', new Date());
      if ((++this.uptime % 30) == 0) {
        eventbus.emit('stale', new Date());
      }      
    }, 1000);
  },
  
  unmounted() {
    window.removeEventListener('resize', this.documentResized);
  },
  
  methods: {
    documentResized(_e) {
      const f = document.documentElement.clientWidth < 450;
      this.isMobileInterface = f;
    },
  }
}
</script>



